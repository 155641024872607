import React from 'react';
import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import kebabCase from 'lodash/kebabCase';
import Sidebar from '../components/Sidebar';
import Layout from '../components/Layout'

class TagsRoute extends React.Component {
    render() {
        const {title} = this.props.data.site.siteMetadata;
        const tags = this.props.data.allMarkdownRemark.group;

        return (
            <Layout>
                <div>
                    <Helmet title={`Alle Tags - ${title}`}/>
                    <Sidebar {...this.props} />
                    <div className="content">
                        <div className="content__tags-page">
                            <div className="page">
                                <h1 className="page-title">
                                    <span className="white-text-chateau-bg fixed-padding-left-increased">
                                        Alle Tags
                                    </span>
                                </h1>
                                <div className="page__body">
                                    <div className="tags">
                                        <ul className="tags__list">
                                            {tags.map(tag => {
                                                if (tag.totalCount === 1) {
                                                    return <li key={tag.fieldValue} className="tags__list-item tags__list-item--normal">
                                                        <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}
                                                              className="tags__list-item-link">
                                                            {tag.fieldValue} ({tag.totalCount})
                                                        </Link>
                                                    </li>
                                                } else if (tag.totalCount === 2) {
                                                    return <li key={tag.fieldValue} className="tags__list-item tags__list-item--medium">
                                                        <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}
                                                              className="tags__list-item-link">
                                                            {tag.fieldValue} ({tag.totalCount})
                                                        </Link>
                                                    </li>
                                                } else {
                                                    return <li key={tag.fieldValue} className="tags__list-item tags__list-item--big">
                                                        <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}
                                                              className="tags__list-item-link">
                                                            {tag.fieldValue} ({tag.totalCount})
                                                        </Link>
                                                    </li>
                                                }
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default TagsRoute;

export const pageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
          email
          twitter
          facebook
          meetup
          xing
          instagram
        }
      }
    }
    allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { layout: { eq: "post" }, draft: { ne: true } } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
